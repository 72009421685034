import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptorModule } from './core/interceptors/request-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from './shared/components/dialog-box/dialog.module';
import { DialogService } from './shared/components/dialog-box/dialog.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { PipesModule } from './core/pipes/pipes.module';
import { environment } from '../environments/environment';
import { DatePipe } from '@angular/common';
import { MaterialModule } from './material/material.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpRequestInterceptorModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
    PipesModule,
    MaterialModule
    ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
